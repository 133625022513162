.pageTitle {
  box-sizing: border-box;
  background-color: white;
  padding: 2px 5px;
  border-bottom: 1px solid #f2e0f7;
  top: 0;
  display: flex;
  /* height: 4rem; */
  height: fit-content;
  align-items: center;
  position: sticky;
  top: 0px;
}
@media screen and (min-width: 1400px) {
  .pageTitle {
    height: 3rem;
    padding: 5px 2rem;
  }
}
@media screen and (min-width: 1800px) {
  .pageTitle {
    height: 3rem;
    padding: 5px 2rem;
  }
}
.pageTitleImage {
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  /* padding-right: 10px; */
}
@media screen and (min-width: 1400px) {
  .pageTitleImage {
    width: 1.75rem;
    height: 1.75rem;
  }
}
@media screen and (min-width: 1800px) {
  .pageTitleImage {
    width: 2rem;
    height: 2rem;
  }
}

.pageTitleText {
  vertical-align: top;
  padding-left: 10px;
  line-height: 1.5rem;
  font-size: 12px;
  color: #1f43e5;
  font-weight: 500;
  text-transform: uppercase;
  flex: 1;
}
@media screen and (min-width: 1400px) {
  .pageTitleText {
    line-height: 2.5rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 1800px) {
  .pageTitleText {
    line-height: 2.5rem;
    font-size: 16px;
  }
}
.buttonList {
  margin: auto;
}
.exportBtn {
  margin: auto;
  float: right;
}
.exportButton {
  width: 9rem;
  margin: 0 auto;
  padding: 7px 5px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: linear-gradient(90deg, #e51f29, #e51f29);
  background-color: white;
  color: white;
  margin-left: 5px;
  position: relative;

}
.imageIconButton {
  width: 2.25rem;
  margin: 0 auto;
  padding: 1px;
  vertical-align: middle;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  background-color: white;
  color: white;
  margin-left: 5px;
}
.imageIcon {
  width: 100%;
  height: 100%;
}
.imageIconButton:hover {
  background-color: lightblue;
}
.imageIcon:hover {
  background-color: lightblue;
}