.rolePagesContainer {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.navButtons {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 1rem;
  background-color: #eee;
}
.roleButton {
  width: 9rem;
  margin: 0 auto;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: linear-gradient(90deg, #1f43e5, #5a6ec5);
  color: #fff;
}
@media screen and (min-width: 1400px) {
  .roleButton {
    width: 12rem;
    font-size: 12px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1800px) {
  .roleButton {
    width: 12rem;
    font-size: 16px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
.goBackButton {
  width: 9rem;
  margin: 0 auto;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: linear-gradient(-90deg, gray, #666);
  color: #fff;
  margin-left: 5px;
}
@media screen and (min-width: 1400px) {
  .goBackButton {
    width: 10rem;
    font-size: 12px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1800px) {
  .goBackButton {
    width: 12rem;
    font-size: 16px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1400px) {
  .goBackButton {
    margin-left: 5px;
  }
}
@media screen and (min-width: 1800px) {
  .goBackButton {
    margin-left: 5px;
  }
}
.roleListArea {
  flex: 1;
  box-sizing: border-box;
  width: 80%;
  margin: 2rem auto;
  overflow: auto;
}
.roleListTable {
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
}
.roleListHeader {
  background-color: #fff;
  position: sticky;
  top: 0;
  border-collapse: separate;
  border-spacing: 0 1px;
  border-bottom: 10px solid #eee;
  box-shadow: 0 0 2px 2px #d3d3d3;
}
.roleNameHeader {
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  color: #14c76a;
  padding: 10px 20px;
}
@media screen and (min-width: 1400px) {
  .roleNameHeader {
    font-size: 12px;
    padding: 15px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .roleNameHeader {
    font-size: 12px;
    padding: 15px 30px;
  }
}
.roleActionHeader {
  color: #14c76a;
  font-weight: 500;
  font-size: 12px;
  text-align: end;
  padding: 10px 20px;
}
@media screen and (min-width: 1400px) {
  .roleActionHeader {
    font-size: 12px;
    padding: 15px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .roleActionHeader {
    font-size: 12px;
    padding: 15px 30px;
  }
}
.roleList {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  color: #666;
  font-size: 12px;
  background-color: white;
  border-top: 5px solid #eee;
}
.roleName {
  padding: 5px 20px;
  font-size: 12px;
}
@media screen and (min-width: 1400px) {
  .roleName {
    padding: 10px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .roleName {
    padding: 10px 30px;
  }
}
.roleAction {
  padding: 5px 20px;
  text-align: end;
}
@media screen and (min-width: 1400px) {
  .roleAction {
    padding: 10px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .roleAction {
    padding: 10px 30px;
  }
}
.roleEditImage {
  box-sizing: border-box;
  margin: 0 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
}
.controlRoleList {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  color: #666;
  font-size: 12px;
  background-color: white;
  border-top: 5px solid #eee;
  cursor: pointer;
}
.roleNameBold {
  flex: 1;
  padding-left: 20px;
  overflow: hidden;
}
@media screen and (min-width: 1400px) {
  .roleNameBold {
    padding: 0px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .roleNameBold {
    padding: 0px 30px;
  }
}
.roleControls {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  color: #333;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  padding: 0 2rem;
  height: 100%;
}
.controlList {
  margin: 0;
  padding: 0;
  background-color: #eee;
}
.roleListInnerTable {
  box-sizing: border-box;
  width: 100%;
  padding: 0 2rem 2rem;
}
.controlRoleName {
  box-sizing: border-box;
  border-bottom: 3px solid #fff;
  padding: 10px;
  margin: 0;
  flex: 1;
  font-size: 12px;
}
.controlRoleNameTitle {
  font-weight: 500;
  font-size: 12px;
}
.controlRoleListHeader {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  display: flex;
  background-color: #eee;
  border-bottom: 3px solid #fff;
  padding: 10px;
}
.createRole {
  box-sizing: border-box;
  height: 2.5rem;
  padding: 5px 20px;
  line-height: 2.5rem;
  background-color: #fff;
  color: #333;
  font-weight: normal;
  margin-top: 5px;
  width: 100%;
}
.createRoleInput {
  border: 2px solid #d3d3d3;
  font-size: 12px;
  /* padding-left: 20px; */
  margin-left: 20px;
  width: 235px;
}
.cancelRoleButton {
  width: 9rem;
  margin: 0 auto;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: auto 0 auto 10px;
  background: linear-gradient(-90deg, #a8a8a8, #999);
  color: #fff;
}
@media screen and (min-width: 1400px) {
  .cancelRoleButton {
    width: 12rem;
    font-size: 12px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1800px) {
  .cancelRoleButton {
    width: 12rem;
    font-size: 16px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1400px) {
  .cancelRoleButton {
    margin: auto 0 auto 10px;
  }
}
@media screen and (min-width: 1800px) {
  .cancelRoleButton {
    margin: auto 0 auto 10px;
  }
}
.createRoleButton {
  width: 9rem;
  margin: 0 auto;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: auto 0 auto 10px;
  background: linear-gradient(90deg, #1f43e5, #5a6ec5);
  color: #fff;
}
@media screen and (min-width: 1400px) {
  .createRoleButton {
    width: 12rem;
    font-size: 12px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1800px) {
  .createRoleButton {
    width: 12rem;
    font-size: 16px;
    margin: 0 auto;
    padding: 7px 5px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1400px) {
  .createRoleButton {
    margin: auto 0 auto 10px;
  }
}
@media screen and (min-width: 1800px) {
  .createRoleButton {
    margin: auto 0 auto 10px;
  }
}
.roleControlArea {
  box-sizing: border-box;
  width: 90%;
  flex: 1;
  margin: 1rem auto;
}
.createRoleControlRole {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  font-size: 16px;
  overflow: auto;
  margin: 2rem;
}
.bossRole {
  flex: 1;
  font-size: 12px;
  box-sizing: border-box;
}
.reporteeRole {
  margin: 0 2rem;
  flex: 1;
  font-size: 16px;
  border-radius: 5px;
  overflow: auto;
}
.hintText2 {
  font-size: 20px;
  font-weight: bold;
  color: #14c76a;
}
.bossRoleDropdown {
  display: block;
  width: 70%;
  min-width: 300px;
  margin: 1rem auto 0 0;
  height: 3rem;
  font-size: 16px;
}
.bossRoleDropdown select {
  width: 100%;
  height: 100%;
  border: 2px solid #d3d3d3;
  padding: 0 10px;
  font-size: 16px;
}
.bossRoleDropdown select::after {
  background-color: red;
  margin: 5px auto;
  height: 3rem;
}
.controlRolesList {
  display: grid;
  width: 50%;
  min-width: 300px;
  grid-template-columns: repeat(1, 1fr);
  font-size: 16px;
  border-radius: 5px;
  overflow: visible;
  margin: 1rem auto 0 0;
}
.controlRoleUnit {
  height: 2rem;
  line-height: 2rem;
  width: max-content;
}
.controlRoleDescription {
  padding-left: 10px;
}
.formSubmitInnerAreaRoles {
  margin: auto;
  display: flex;
  padding: 0 2rem;
}
.bossRoleDisplay {
  box-sizing: border-box;
  line-height: 2.5rem;
  background-color: white;
  width: 100%;
  height: 100%;
  border: 2px solid #d3d3d3;
  padding: 0 10px;
  text-align: center;
  font-size: 20px;
}
.screenControl {
  margin: 0 2rem 2rem 2rem;
  overflow: visible;
  height: 285px;
}
.screenControlTable {
  margin: 1rem 2rem;
}
.screenControlTableHead {
  padding: 5px 20px 20px;
  color: #333;
}
.screenControlTableData {
  text-align: center;
  padding: 0.25rem;
}
.screenControlModuleName {
  color: #14c76a;
}
.screenControlTableReset {
  cursor: pointer;
  background-color: #dcdcdc;
  width: 1.5rem;
  height: 1.5rem;
  margin: 2px 10px 2px 20px;
  border-radius: 5px;
}
.screenControlOptionsWrapper {
  width: 220px;
  margin-bottom: 2rem;
  margin-left: 20px;
}
