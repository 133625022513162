.completePage {
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh);
    height: calc(100vh);
    display: flex
}

.detailsContainer {
    box-sizing: border-box;
    flex: 1;
    height: calc(100vh);
    background-color: #f3f5f9;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;

}


@media screen and (max-width: 1300px) {
    .detailsContainer{
        margin-left: 60px;
        width: 95vw;
        overflow-x: hidden;
    }
}
/* @media screen and (max-width: 860px) {
    .detailsContainer{
        margin-left: 60px;
        width: 95vw;
        overflow-x: hidden;
    }
} */
.employeeButton {
    width: 9rem;
    /* width: 5rem; */
    /* width: fit-content; */
    margin: 2px auto;
    padding: 5px 12px;
    font-size: 11px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, #1f43e5, #1f43e5);
    background-color: white;
    color: white;
    margin-left: 5px;
    font-family:  "Poppins", -apple-system, BlinkMacSystemFont,sans-serif;
}

@media screen and (min-width: 1400px) {
    .employeeButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .employeeButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1400px) {
    .employeeButton {
        margin-left: 5px
    }
}

@media screen and (min-width: 1800px) {
    .employeeButton {
        margin-left: 5px
    }
}

.modalButton {
    width: 9rem;
    margin: 0 auto;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, #1f43e5, #1f43e5);
    margin: 0 auto;
    color: #fff
}

@media screen and (min-width: 1400px) {
    .modalButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .modalButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

.employeeListArea {
    box-sizing: border-box;
    margin: 2rem;
    overflow: auto
}

.employeeListTable {
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;
    overflow-x: auto
}

.employeeListHeader {
    background-color: #fff;
    position: sticky;
    top: 0;
    border-collapse: separate;
    border-spacing: 0 1px;
    border-bottom: 10px solid #eee;
    box-shadow: 0 0 2px 2px #d3d3d3
}

.employeeNameHeader {
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    color: #14c76a;
    padding: 10px 20px
}

@media screen and (min-width: 1400px) {
    .employeeNameHeader {
        padding: 15px 30px
    }
}

@media screen and (min-width: 1800px) {
    .employeeNameHeader {
        padding: 15px 30px
    }
}

.employeeActionHeader {
    color: #14c76a;
    font-weight: 500;
    font-size: 12px;
    text-align: end;
    padding: 10px 20px
}

@media screen and (min-width: 1400px) {
    .employeeActionHeader {
        padding: 15px 30px
    }
}

@media screen and (min-width: 1800px) {
    .employeeActionHeader {
        padding: 15px 30px
    }
}

.employeeList {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    font-size: 20px;
    color: #14c76a;
    background-color: #fff;
    color: #666;
    font-size: 12px;
    margin-top: 5px;
    overflow: hidden;
    border-radius: 5px;
    border-top: 5px solid #eee
}

.employeeName {
    padding: 10px 20px
}

@media screen and (min-width: 1400px) {
    .employeeName {
        padding: 10px 30px
    }
}

@media screen and (min-width: 1800px) {
    .employeeName {
        padding: 10px 30px
    }
}

.employeeAction {
    padding: 10px 30px;
    text-align: end
}

.employeeActionImage {
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px 4px;
    height: 1rem;
    cursor: pointer
}

@media screen and (min-width: 1400px) {
    .employeeActionImage {
        padding: 2px 4px;
        height: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .employeeActionImage {
        padding: 4px;
        height: 1.25rem
    }
}

.formArea {
    flex: 1;
    box-sizing: border-box;
    background-color: #f3f3f3;
    margin-top: 0.5rem;
    padding-top: 1.5rem;
    overflow: auto
}

.formGrid {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(4, 4rem)
}

.profilePhoto {
    box-sizing: border-box;
    color: #666;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    grid-column: auto / span 3;
    grid-row: auto / span 2;
    display: flex;
    flex-direction: column;
    position: relative
}

.photoArea {
    flex: 1;
    background-color: #d3d3d3;
    border-radius: 10px;
    max-width: 100%;
    object-fit: contain;
    max-height: calc(8rem + 10px)
}

.uploadEditProfilePhoto {
    position: absolute;
    padding: 2px 5px;
    border-radius: 10px 0 10px 0;
    background-color: gray;
    color: white;
    bottom: 0;
    right: 0;
    cursor: pointer
}

.uploadEditProfilePhotoError {
    position: absolute;
    padding: 5px;
    border-radius: 0 0 5px 0;
    background-color: white;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: 1px solid #ee3f3f
}

.uploadEditProfilePhoto:hover {
    background-color: #5f5f5f;
    color: white
}

#uploadProfilePhoto {
    opacity: 0;
    position: absolute;
    z-index: -1
}

.name,
.role {
    grid-column: auto / span 3
}

.email {
    grid-column: auto / span 4
}

.general {
    grid-column: auto / span 4
}

.subTitle {
    box-sizing: border-box;
    margin: 3rem 0 1rem;
    font-weight: 500;
    font-size: 12px;
    color: #1f43e5;
    text-transform: uppercase
}

@media screen and (min-width: 1400px) {
    .subTitle {
        font-size: 14px
    }
}

@media screen and (min-width: 1800px) {
    .subTitle {
        font-size: 14px
    }
}

.personalDetails {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(5, 4rem)
}

.emergencyDetails {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(1, 4rem)
}

.bankDetails {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(2, 4rem)
}

.accountPassword {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(1, 4rem)
}

.additionalNotes {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(1, 4rem)
}

.formSubmitArea {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid #f2e0f7
}

.formSubmitInnerArea {
    width: 75%;
    margin: auto;
    display: flex;
    padding: 5px 20px
}

.formSubmitErrorTextArea {
    flex: 1;
    color: red;
    font-size: 20px;
    margin: auto;
    text-align: left
}

.submitButton {
    width: 9rem;
    margin: 0 auto;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 5px;
    background-color: #1f43e5;
    color: #fff
}

@media screen and (min-width: 1400px) {
    .submitButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .submitButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1400px) {
    .submitButton {
        margin: 5px
    }
}

@media screen and (min-width: 1800px) {
    .submitButton {
        margin: 5px
    }
}

.cancelButton {
    width: 9rem;
    margin: 0 auto;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 5px;
    background: gray;
    color: #fff
}

@media screen and (min-width: 1400px) {
    .cancelButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .cancelButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1400px) {
    .cancelButton {
        margin: 5px
    }
}

@media screen and (min-width: 1800px) {
    .cancelButton {
        margin: 5px
    }
}

.employeeSummary {
    width: 100%;
    margin: 0 auto;
    display: flex
}

.employeeImage {
    box-sizing: border-box;
    background-color: white;
    border: 2px solid lightgray;
    border-radius: 5px;
    width: 10rem;
    height: 10rem;
    min-width: 10rem;
    min-height: 10rem;
    object-fit: contain
}

.employeeCurrentStatus {
    width: 100%;
    text-align: center
}

.employeeStatus {
    font-size: 16px;
    font-weight: bold;
    color: #029402
}

.employeeStatusInactive {
    font-size: 16px;
    font-weight: bold;
    color: #9e9e9e
}

.employeeSummaryText {
    margin: 0 50px
}

.employeeSummaryName {
    font-size: 20px;
    font-weight: bold
}

.employeeSummaryRole {
    font-size: 20px;
    color: #14c76a
}

.employeeSummaryGrid {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px 5px;
    white-space: nowrap;
    overflow: auto
}

.employeeSummaryCategory {
    font-weight: bold;
    padding-right: 5px
}

.employeeSummaryValue {
    word-wrap: normal
}

.employeeDetailCards {
    width: 100%;
    padding: 20px;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    overflow: auto
}

.detailCard {
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px 60px;
    box-shadow: 0 0 3px 0px #d3d3d3
}

.detailCardTitle {
    font-size: 20px;
    font-weight: bold;
    color: #14c76a
}

.employeeSummaryCardGrid {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 2fr 5px 3fr;
    gap: 5px;
    white-space: nowrap
}

.employeeSummaryCardCategory {
    font-weight: bold;
    white-space: pre-wrap;
    padding-right: 5px;
    overflow: hidden
}

.employeeSummaryCardValue {
    padding-left: 10px;
    white-space: pre-wrap;
    overflow: hidden
}

.modalImage {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.loader {
    border: 5px solid #dbdbdb;
    border-top: 5px solid #1f43e5;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 1s linear infinite;
    margin: auto
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}