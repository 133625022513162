.formElement {
    height: 100%;
    display: flex;
    flex-direction: column
}
@media screen and (max-width: 860px) {
    .formElement{
        height: 50%;
        display: flex;
        flex-direction: column;
        margin: 1px;
    }
     .formElement > :first-child{
        margin: 0px;
    } 
    .react-datepicker-wrapper > *{
        font-size: smaller;
    }
    /* .react-datepicker {
        position:absolute !important;
    right: -290px !important;
    } */
}
.hintText {
    font-size: 10px;
    color: #999;
    padding-right: 5px;
    margin-bottom: 5px
}

.mandatory {
    line-height: 10px;
    font-size: 10px;
    font-weight: bold;
    color: red
}

.commonInput {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding: 0 10px
}

.commonInputError {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #ee3f3f
}

.commonDropdown {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding-left: 10px
}

.commonDropdownError {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding-left: 10px;
    border: 1px solid #ee3f3f
}

.react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #c0b6b6 !important;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    background-color: white
}

.datePickerWrapperCustom {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(204, 204, 204) !important;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding: 4px !important;
}
.datePickerWrapperCustomError{
border: 1px solid red !important;
}
.datePickerWrapperDisabled {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 20px;
    flex: 1;
    border-radius: 10px;
    background-color: #f0f0f0
}

.react-datepicker__input-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* border: 1px solid #ddd !important; */
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    border: none
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    color: #b3b3b3;
    background-color: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px 2px 3px 2px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7"
}

.commonDatepicker {
    height: 100%;
    padding-left: 10px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: white;
}

.inputFile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    font-size: 12px;
    padding-left: 5px;
    padding-top: 8px
}

.inputDone {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden
}

.inputLabel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#fff, #d3d3d3);
    cursor: pointer
}

.inputLabelError {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    flex: 1;
    border-radius: 10px;
    padding-top: 8px;
    text-align: center;
    background: linear-gradient(#fff, #d3d3d3);
    cursor: pointer;
    border: 1px solid #ee3f3f
}

.inputLabel:hover {
    background: linear-gradient(#d3d3d3, #a9a9a9);
    color: white
}

.inputLabelError:hover {
    background: linear-gradient(#d3d3d3, #a9a9a9);
    color: white
}

#upload_photo {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: -1
}

#upload_file {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: -1
}

.formAddButton {
    height: 100%;
    padding: 0;
    text-align: center;
    background-color: #1f43e5;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.75rem;
    margin-top: 2em !important;
}

.formAddButtonDisabled {
    height: 100%;
    padding: 0;
    text-align: center;
    background-color: #ff9011;
    color: #fff;
    border: 1px solid transparent;
    cursor: no-drop;
    display: flex;
    align-items: center;
    justify-content: center
}

.topBannerDiv {
    box-sizing: border-box;
    height: 2rem;
    background-color: white;
    display: flex;
    border-bottom: 1px solid #f2e0f7;
    position: sticky;
    top: 0px;
}

@media screen and (min-width: 1400px) {
    .topBannerDiv {
        height: 3rem
    }
}

@media screen and (min-width: 1800px) {
    .topBannerDiv {
        height: 3rem
    }
}

.companyName {
    width: 16rem;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    line-height: 24px;
    padding: 0.75rem 1rem
}

.searchBar {
    box-sizing: border-box;
    margin: 0.5rem 0 0.5rem 2px;
    height: 2rem;
    width: 30rem;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px
}

.logOutGroup {
    display: flex;
    cursor: pointer
}

.logOutGroup:hover {
    background-color: #f3f5f9;
    display: flex;
    cursor: pointer
}

.logoutText {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    line-height: 16px;
    padding: 1rem 1rem 1rem 0.5rem
}

.logoutPopoverContent {
    width: 10rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
    margin-right: 0.5rem;
    border: 1px solid #f2e0f7;
    box-shadow: 0 5px 5px 2px lightgray;
    align-content: center
}

@media screen and (min-width: 1400px) {
    .logoutPopoverContent {
        width: 14rem
    }
}

@media screen and (min-width: 1800px) {
    .logoutPopoverContent {
        width: 14rem
    }
}

.topBannerUserImage {
    box-sizing: border-box;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.25rem 1rem;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #f2e0f7
}

@media screen and (min-width: 1400px) {
    .topBannerUserImage {
        height: 2.5rem;
        width: 2.5rem;
        margin: 0.25rem 2rem
    }
}

@media screen and (min-width: 1800px) {
    .topBannerUserImage {
        height: 2.5rem;
        width: 2.5rem;
        margin: 0.25rem 2rem
    }
}

.popoverUserImage {
    box-sizing: border-box;
    height: 6rem;
    width: 6rem;
    margin: 1rem 2rem;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid lightgray
}

@media screen and (min-width: 1400px) {
    .popoverUserImage {
        height: 8rem;
        width: 8rem;
        margin: 1rem 3rem
    }
}

@media screen and (min-width: 1800px) {
    .popoverUserImage {
        height: 8rem;
        width: 8rem;
        margin: 1rem 3rem
    }
}

.popoverEmployeeName {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #1f43e5;
    font-weight: 600
}

@media screen and (min-width: 1400px) {
    .popoverEmployeeName {
        font-size: 20px
    }
}

@media screen and (min-width: 1800px) {
    .popoverEmployeeName {
        font-size: 20px
    }
}

.popoverEmployeeId {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #666
}

@media screen and (min-width: 1400px) {
    .popoverEmployeeId {
        font-size: 16px
    }
}

@media screen and (min-width: 1800px) {
    .popoverEmployeeId {
        font-size: 16px
    }
}

.logoutButton {
    width: 50%;
    height: 2rem;
    margin: 1rem 2.5rem 0rem;
    background-color: #eee;
    border: 2px solid transparent;
    border-radius: 5px;
    color: #14c76a;
    font-size: 12px;
    font-weight: 600
}

@media screen and (min-width: 1400px) {
    .logoutButton {
        height: 3rem;
        margin: 2rem 3.5rem 0rem;
        font-size: 16px
    }
}

@media screen and (min-width: 1800px) {
    .logoutButton {
        height: 3rem;
        margin: 2rem 3.5rem 0rem;
        font-size: 16px
    }
}

.logoutButton:hover {
    border: 2px solid #d3d3d3;
    cursor: pointer
}

.errorHeading {
    font-size: 20px;
    padding-bottom: 1rem
}

.errorSection {
    font-size: 16px;
    font-weight: 550;
    text-align: center;
    color: red;
    text-decoration: underline
}

.errorDetail {
    padding: 0 20px;
    /* text-align: left */
    text-align: center;
}

.noRecordsYet {
    text-align: center;
    font-size: 24px;
    color: #ccc;
    font-weight: 400;
    padding: 3rem 0;
    font-style: italic
}

.noAccessContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    color: #1f43e5;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    font-weight: bold
}

.noAccessBackToHome {
    cursor: pointer;
    text-decoration: underline
}

.poDeclineMultilineInput {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #c8c8c8;
    color: #666;
    font-size: 16px;
    background-color: white;
    flex: 1;
    padding: 10px
}

.poDeclineMultilineInputError {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid red;
    color: #666;
    font-size: 16px;
    background-color: white;
    flex: 1;
    padding: 10px
}

.purchaseImportInput {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #c8c8c8;
    color: #666;
    font-size: 16px;
    flex: 1;
    background-color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#fff, #d3d3d3);
    cursor: pointer
}
.customLineCheck{
    font-size: 15px;
    width: 60px;
    color: #1d4bcc;
    margin-top: 15px;
    font-weight: normal;
    cursor: pointer;
    grid-area: auto / auto / span 1/ span 2;
}
.customLineCheck > img{
    font-size: 24px;
    height: 20px;
    width: 20px;
    margin: 2px;
}
 .disableBomPOPup {
    color: gray;
    position: relative;
    display: inline-block;
}
 .disableBomPOPup > img{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
.disableBomPOPup:after {
    content: "Please select Line item";
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    width: max-content;
    font-size: 12px;
    display: none;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.696);
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    z-index: 19;
  }
  .disableBomPOPup:hover:after {
    display: block;
  }

  .saleOrderHeader{
    display: inline-block;
    padding:  0 30%;
    text-align: center;
  }

  .saleOrderFooter{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3.5fr 1fr 1.5fr;
    text-align: center;
  }

  .bomLevelColumn{
    display: flex;
    justify-content: space-around;
    font-size: medium;
    max-width: 55px;
    font-family: cursive;
  }