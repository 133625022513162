* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  /* background-color: lightgray; */
}

*::-webkit-scrollbar-thumb {
  background:  rgba(31, 67, 229, 0.208);
  border-radius: 5px;
}
@media screen and (max-width: 1300px) {
  *::-webkit-scrollbar{
    height: 6px;
    width: 6px;
  }
  *::-webkit-scrollbar-thumb{
    background-color: rgba(31, 67, 229, 0.573);
  }
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(31, 67, 229, 0.573);
}
img[src=""],
img:not([src]) {
  opacity: 0;
}

.sortArrow {
  width: 15px;
  height: 15px;
  border: none;
  margin-left: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*  Force Landscape Mode For Min Screen devices */

/* @media screen and (max-width: 767px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}  */